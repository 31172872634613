import { css } from '@emotion/react';
import { TextField } from '@mui/material';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
import { SymbolGroupVolumeFilterSwitch } from '~/modules/investment-consultant/SymbolGroupTable/SymbolGroupVolumeFilterSwitch';
export const VolumeFilter = memo(function VolumeFilter() {
    const symbolGroupState = useSnapshot(symbolGroupStore);
    const textFieldCommonProps = {
        InputProps: {
            style: {
                height: 35,
                color: '#ffffff',
            },
        },
    };
    return (<div css={containerCss}>
      <div css={titleCss}>成交量篩選</div>
      <div css={switchCss}>
        <SymbolGroupVolumeFilterSwitch />
      </div>
      <div css={inputCss}>
        <TextField {...textFieldCommonProps} type={'number'} variant={'outlined'} size={'small'} value={symbolGroupState.volumeLimit} onChange={event => {
            const value = Number(event.target.value);
            if (value >= 0)
                symbolGroupStore.volumeLimit = value;
        }}/>
        張
      </div>
    </div>);
});
const containerCss = css `
  ${flex.h.crossCenter};
  width: 100%;
  height: 40px;
  background-color: #1d2b38;
`;
const titleCss = css `
  ${flex.h.allCenter};
  height: 40px;
  width: 100px;
`;
const switchCss = css `
  ${flex.h.allCenter};
  height: 35px;
  width: 80px;
`;
const inputCss = css `
  ${flex.h.allCenter};
  height: 35px;
  width: calc(100% - 180px);
`;
