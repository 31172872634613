import { memo } from 'react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { Styleds } from '../styleds';
import { winner98_agentStore } from '../winner98_agentStore';
export const PageModeButtonSelector = memo(function PageModeButtonSelector() {
    return (<Styleds.PageModeContent>
      <winner98_agentStore.tabs1.WithButton to='主力選股'>
        <FuiButton.Display>帝王精選</FuiButton.Display>
      </winner98_agentStore.tabs1.WithButton>

      <winner98_agentStore.tabs1.WithButton to='熱門股'>
        <FuiButton.Display>市場熱門</FuiButton.Display>
      </winner98_agentStore.tabs1.WithButton>

      <winner98_agentStore.tabs1.WithButton to='大師選股'>
        <FuiButton.Display>大師優選</FuiButton.Display>
      </winner98_agentStore.tabs1.WithButton>

      <winner98_agentStore.tabs1.WithButton to='自選股'>
        <FuiButton.Display>自選股</FuiButton.Display>
      </winner98_agentStore.tabs1.WithButton>
    </Styleds.PageModeContent>);
});
