import { css } from '@emotion/react';
import { Fragment, memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import { Styleds } from './styleds';
import { winner98_agentStore } from './winner98_agentStore';
import { UnregisteredView } from '~/modules/investment-consultant/loginPage/UnregisteredView';
import { Winner98StrategyButton1 } from './winner98_SidebarPane1';
const logoImageCss = css `
  width: 200px;
`;
const selectorCss = css `
  width: 400px;
  height: 48px;
  padding: 8px;
  gap: 4px;
`;
export const Winner98_Topbar = memo(function Winner98_Topbar() {
    const { isPc } = useMedia();
    return (<Fragment>
      {isPc && (<Styleds.Topbar>
          <div css={flex.h.allCenter}>
            <img css={logoImageCss} src='winner98/favicon4.png'/>
          </div>
          <div css={flex.h.allCenter}>
            <LinkGroup />
            <ChartingServerSwitch charting={store.charting} css={css `
                height: 32px;
              `}/>
            <div css={selectorCss}>
              <Winner98StrategyButton1 />
            </div>
            <winner98_agentStore.userAvatar.AvatarModal cssset={css `
                width: 280px;
                height: 480px;
              `} blur={1}>
              <UnregisteredView />
            </winner98_agentStore.userAvatar.AvatarModal>
          </div>
        </Styleds.Topbar>)}
      {!isPc && (<Styleds.Topbar>
          <Preset_Topbar showLeftBurger componentsInRight={<div css={fill_horizontal_cross_center}>
                <ChartingServerSwitch charting={store.charting}/>
                <winner98_agentStore.userAvatar.AvatarModal cssset={css `
                    width: 280px;
                    height: 480px;
                  `} blur={1}>
                  <UnregisteredView />
                </winner98_agentStore.userAvatar.AvatarModal>
              </div>}></Preset_Topbar>
        </Styleds.Topbar>)}
    </Fragment>);
});
export const LinkGroup = function LinkGroup() {
    return (<div css={css `
        ${flex.h.allCenter};
        ${jc.spaceBetween};
        height: 30px;
        padding: 0px 4px;
        gap: 10px;
      `}>
      <div css={css `
          ${flex.h.allCenter};
          width: 80px;
          background-color: #555555;
          border-radius: 5px;
        `}>
        他網參考
      </div>
      <a href={'https://tw.stock.yahoo.com/news/'} css={css `
          ${flex.h.allCenter}
          color:#57d4fa;
          &:hover {
            color: #57a0ff;
          }
        `} target='_blank' rel='noreferrer'>
        Yahoo新聞
      </a>
      <a href={'https://www.cnyes.com/'} css={css `
          ${flex.h.allCenter}
          color:#57d4fa;
          &:hover {
            color: #57a0ff;
          }
        `} target='_blank' rel='noreferrer'>
        鉅亨網
      </a>
      <a href={'https://cryptowin.vercel.app/'} css={css `
          ${flex.h.allCenter}
          color:#57d4fa;
          &:hover {
            color: #57a0ff;
          }
        `} target='_blank' rel='noreferrer'>
        國際財經
      </a>
    </div>);
};
