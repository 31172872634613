import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
//熱門股
export const Winner98_PopularSymbol = memo(function Winner98_PopularSymbol() {
    const tw50 = useSnapshot(staticStore).symbol.tw50.map(s => s);
    return (<SimpleQuoteListInteractWithChart data={tw50 ?? []} chart={store.charting}/>);
});
