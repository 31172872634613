import { useIndicatorStore } from '~/store/useIndicatorStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { usePageWinner98Store } from '~/pages/winner98/modules/usePageWinner98Store';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
export const winner98_signal = createIndicator({
    displayName: '帝王決策',
    id: 'winner98signal',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const positionType = useChartPositionStore.positionType;
            const type = usePageWinner98Store.getState().strategyType;
            const state = useIndicatorStore.getState();
            const time = this.PineJS.Std.time(this._context);
            const localTimeVar = new Date().getTime();
            const close = this.PineJS.Std.close(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const close_array = this._context.new_var(close);
            const inputType = this._input(4);
            const kbarLoadingTimeMs = 3000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            //ma
            const ma1 = 80; //80
            const ma2 = 52; //52
            const ma3 = 48; //48
            const ma4 = 26; //26
            //macd
            const macd1 = 7;
            const macd2 = 16;
            const macd3 = 5;
            //bisa
            const bisama = this.PineJS.Std.sma(close_array, 47, this._context);
            const bState = this._context.new_var();
            const bhold = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryTime = this._context.new_var(); //紀錄部位成立的時間
            const entryPrice = this._context.new_var();
            let B_signal = NaN;
            let S_signal = NaN;
            let pb1 = NaN;
            let ps1 = NaN;
            let pb2 = NaN;
            let ps2 = NaN;
            // let pb3 = NaN
            // let ps3 = NaN
            //ma
            const sma1 = this.PineJS.Std.sma(close_array, ma1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, ma2, this._context);
            const sma3 = this.PineJS.Std.sma(close_array, ma3, this._context);
            const sma4 = this.PineJS.Std.sma(close_array, ma4, this._context);
            const sma2_array = this._context.new_var(sma2);
            sma2_array.get(0);
            sma2_array.get(1);
            entryTime.get(1);
            //macd
            const fast = this.PineJS.Std.ema(close_array, macd1, this._context);
            const slow = this.PineJS.Std.ema(close_array, macd2, this._context);
            const dif = fast - slow;
            const dif_array = this._context.new_var(dif);
            const dem = this.PineJS.Std.ema(dif_array, macd3, this._context);
            const osc = dif - dem;
            const osc_array = this._context.new_var(osc);
            //bisa
            const bisa = ((close - bisama) / bisama) * 100;
            osc_array.get(1);
            bState.get(1);
            /** 在負值 綠柱狀[0]往零軸靠近 */
            const osc_long_state1 = osc_array.get(0) < 0 && osc_array.get(1) < 0 && osc_array.get(0) > osc_array.get(1);
            /** Osc[1]<0 && osc[0]>0 */
            const osc_long_state2 = osc_array.get(0) < 0 && //綠 //反轉
                osc_array.get(1) > 0 && //紅 //反轉
                osc_array.get(0) > osc_array.get(1); //反轉
            /** 在正值 紅柱狀[0]往零軸靠近 */
            const osc_short_state1 = osc_array.get(0) > 0 && osc_array.get(1) > 0 && osc_array.get(0) < osc_array.get(1);
            /** Osc[1]>0 && osc[0]<0 由正值往負值 */
            const osc_short_state2 = osc_array.get(0) > 0 && //紅 //反轉
                osc_array.get(1) < 0 && //綠 //反轉
                osc_array.get(0) < osc_array.get(1); //反轉
            const osc_long_condition = osc_long_state1 || osc_long_state2;
            const osc_short_condication = osc_short_state1 || osc_short_state2;
            //進場邏輯
            const condition3 = dif > 0 && //Dif > 0
                sma2_array.get(0) > sma2_array.get(1) && //均線斜率向上
                osc_long_condition;
            const condition4 = dif < 0 && //Dif < 0
                sma2_array.get(0) < sma2_array.get(1) && //均線斜率向下
                osc_short_condication;
            //出場邏輯
            const condition5 = sma2 > sma1 && dif > 0;
            const condition6 = sma2 < sma1 && dif < 0;
            //----------------------------------------------
            if (inputType !== 2 && condition3 && positionType !== 'short') {
                bState.set(1);
            }
            else if (inputType !== 1 && condition4 && positionType !== 'long') {
                bState.set(-1);
            }
            //---------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                B_signal = 1;
                pvflag.set(0);
                bhold.set(1);
                entryTime.set(time);
                if (localTimeVar) {
                    entryPrice.set(sma3);
                }
                else
                    entryPrice.set(NaN);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                S_signal = 1;
                pvflag.set(0);
                bhold.set(-1);
                entryTime.set(time);
                if (localTimeVar) {
                    entryPrice.set(sma3);
                }
                else
                    entryPrice.set(NaN);
            }
            //---------------------------------------------
            if (bState.get(1) === 1 && bState.get(0) === 1 && close < sma4 && pvflag.get(0) === 0) {
                pvflag.set(1);
                pb1 = 1;
            }
            if (bState.get(1) === -1 && bState.get(0) === -1 && close > sma4 && pvflag.get(0) === 0) {
                pvflag.set(1);
                ps1 = 1;
            }
            //---------------------------------------------
            if (bState.get(1) === 1 && pvflag.get(0) === 1 && close < sma3) {
                pvflag.set(2);
                bState.set(0);
                bhold.set(0);
                pb2 = 1;
                entryPrice.set(NaN);
            }
            if (bState.get(1) === -1 && bState.get(0) === -1 && close > sma3) {
                pvflag.set(2);
                bState.set(0);
                bhold.set(0);
                ps2 = 1;
                entryPrice.set(NaN);
            }
            //一次打到兩種條件
            if (bState.get(1) === 1 && pvflag.get(0) === 1 && close < sma3 && close < sma4) {
                pvflag.set(2);
                bState.set(0);
                bhold.set(0);
                pb2 = 1;
                entryPrice.set(NaN);
            }
            if (bState.get(1) === -1 && bState.get(0) === -1 && close > sma3 && close > sma4) {
                pvflag.set(2);
                bState.set(0);
                bhold.set(0);
                ps2 = 1;
                entryPrice.set(NaN);
            }
            const last_bar = this._context.symbol.isLastBar;
            if (last_bar) {
                state.value1 = Number(bisa);
            }
            const colorIndex = bState.get(0) > 0 ? 0 : bState.get(0) === 0 ? 2 : 1;
            return [
                B_signal,
                S_signal,
                pb1,
                ps1,
                pb2,
                ps2,
                type !== 2 ? colorIndex : NaN,
                entryPrice.get(0),
            ];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_4',
                type: 'shapes',
            },
            {
                id: 'plot_5',
                type: 'shapes',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
            {
                id: 'plot_12',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
            {
                id: 'plot_13',
                type: 'line',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#9C27B0',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#FDD835',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#BDBDBD',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                plot_4: {
                    color: '#CC27B0',
                    textColor: '#CC27B0',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#FAF835',
                    textColor: '#FAF835',
                    transparency: 0,
                    visible: true,
                },
                plot_6: {
                    color: '#ffffff',
                    textColor: '#ffffff',
                    transparency: 0,
                    visible: true,
                },
                plot_7: {
                    color: '#ffffff',
                    textColor: '#ffffff',
                    transparency: 0,
                    visible: true,
                },
                plot_8: {
                    color: '#ffff00',
                    textColor: '#ffff00',
                    transparency: 100,
                    visible: true,
                },
                plot_9: {
                    color: '#ffff00',
                    textColor: '#ffff00',
                    transparency: 100,
                    visible: true,
                },
                plot_13: {
                    linestyle: 2,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !1,
                    color: '#ff0000',
                },
            },
        },
        styles: {
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'small',
                text: 'Buy',
                title: '三角形',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'small',
                text: 'Short',
                title: '三角形',
            },
            plot_6: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: '-',
                text: '',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: '-',
                text: '',
            },
            plot_8: {
                isHidden: false,
                location: 'AboveBar',
                char: '',
                size: 'small',
                title: '-',
            },
            plot_9: {
                isHidden: false,
                location: 'BelowBar',
                char: '',
                size: 'small',
                title: '-',
            },
            plot_12: {
                title: '紫金',
                histogramBase: 0,
            },
            plot_13: {
                title: '危機與轉機線',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
