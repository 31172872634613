import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { store } from '~/pages/heineken_template/_private/store';
export const Winner98_ChartPositionSelector = memo(function Winner98_ChartPositionSelecter() {
    const chartPosition = useSnapshot(useChartPositionStore);
    return (<div css={css `
          ${fill_horizontal_all_center};
          max-width: 600px;
          height: 32px;
          gap: 4px;
          padding: 2px;
        `}>
        <PositionButton variant='long' active={chartPosition.positionType === 'long'} onClick={event => {
            useChartPositionStore.positionType = 'long';
            store.charting.updateFromState();
        }}>
          多方操作
        </PositionButton>
        <PositionButton variant='default' active={chartPosition.positionType === 'default'} onClick={event => {
            useChartPositionStore.positionType = 'default';
            store.charting.updateFromState();
        }}>
          預設
        </PositionButton>
        <PositionButton variant='short' active={chartPosition.positionType === 'short'} onClick={event => {
            useChartPositionStore.positionType = 'short';
            store.charting.updateFromState();
        }}>
          空方操作
        </PositionButton>
      </div>);
});
const PositionButton = styled.div `
  ${flex.h.allCenter};
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 18px;
  border: 1px solid #666666;
  &:hover {
    border: 1px solid #cccccc;
  }

  ${options => {
    const variant = options.variant === 'default' &&
        options.active === false &&
        css `
        background-color: #555555;
        color: #bbbbbb;
      `;
    const variantActive = options.variant === 'default' &&
        options.active === true &&
        css `
        background-color: #555555;
        border: 1px solid #eeeeee;
      `;
    const variantLong = options.variant === 'long' &&
        options.active === false &&
        css `
        background-color: #7f2e98;
        color: #bbbbbb;
      `;
    const variantLongActive = options.variant === 'long' &&
        options.active === true &&
        css `
        background-color: #9031aa;
        border: 1px solid #eeeeee;
      `;
    const variantShort = options.variant === 'short' &&
        options.active === false &&
        css `
        background-color: #989835;
        color: #bbbbbb;
      `;
    const variantShortActive = options.variant === 'short' &&
        options.active === true &&
        css `
        background-color: #acac38;
        border: 1px solid #eeeeee;
      `;
    return css([
        variant,
        variantActive,
        variantLong,
        variantLongActive,
        variantShort,
        variantShortActive,
    ]);
}}
`;
