import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { AppVersion } from '~/components/AppVersion';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
const getMatchedSubscription = (subscriptions, agentName, // target name
product) => {
    const bigProduct = agentName.includes('stock') ? 'stock' : 'web';
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName && subscription.productName === product) {
            return subscription;
        }
    }
    return null;
};
const ItemText = memo(function ItemText(props_) {
    const themeMode = useThemeStore(state => state.theme);
    return <View.itemText theme={themeMode}>{props_.children}</View.itemText>;
});
const ItemValue = memo(function ItemValue(props_) {
    const themeMode = useThemeStore(state => state.theme);
    return <View.itemValue theme={themeMode}>{props_.children}</View.itemValue>;
});
const Item = memo(function Item(props_) {
    return (<div css={css `
        width: 100%;
        height: 50px;
        ${flex.v.allCenter}
      `}>
      <ItemText>{props_.Tilte}</ItemText>
      <ItemValue>{props_.value}</ItemValue>
    </div>);
});
export const UnregisteredView = memo(function UnregisteredView() {
    const themeMode = useThemeStore(state => state.theme);
    const { meUserState } = useMeStore();
    const firebaseMe = useFirebaseMeState.useContainer();
    /** 會員代碼 */
    const userCode = meUserState?.code?.toString() ?? '-';
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    /** 會員信箱 */
    const userEmail = meUserState?.email ?? '-';
    const aaaa = meUserState?.subscriptions;
    /** 產品名稱 */
    const agentName = useMeStore(state => state.agentName);
    /** 產品類別 */
    const product = useMeStore(state => state.product);
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, product);
    }
    /** 到期日期 */
    const expDate = (matchedSubscription && dayAPI(matchedSubscription.expiredAt).format('YYYY/MM/DD')) ??
        '尚未註冊';
    const createdDate = dayAPI(meUserState?.createdAt).format('YYYY/MM/DD');
    /** 產品詳細資訊 */
    const productInfomation = matchedSubscription && matchedSubscription.agentName + '/' + matchedSubscription.productName;
    /** 產品開通狀態 */
    const productStatus = matchedSubscription?.status;
    /** 會員大頭貼 */
    const userPicture = (<UserAvatarAsDialogButton rootCSS={css `
        padding: 32px 32px;
        border-radius: 10px;
      `}/>);
    return (<View.mainContainer theme={themeMode}>
      <View.topContainer theme={themeMode}>
        {userPicture}
        <View.userName theme={themeMode}> {userName}</View.userName>
        <div>會員資料</div>
      </View.topContainer>
      <View.infoContainer>
        <Item Tilte='會員代碼' value={userCode}/>
        <Item Tilte='會員信箱' value={userEmail}/>
        <Item Tilte='到期日期' value={expDate}/>
        <Item Tilte='註冊日期' value={createdDate}/>
        <View.logOutButton onClick={firebaseMe.acts.logout}>登出</View.logOutButton>
      </View.infoContainer>

      <View.bottomContainer theme={themeMode}>
        版本
        <AppVersion></AppVersion>
        {productInfomation}
      </View.bottomContainer>
    </View.mainContainer>);
});
//{productStatus && <img src={`/futuresai/auth/badge-${productStatus}.png`} />}
const View = {
    mainContainer: styled.div `
    ${flex.v.crossCenter};
    ${jc.spaceBetween};
    width: 280px;
    height: 480px;
    border-radius: 5px;
    gap: 12px;

    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          background-color: #313031;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          background-color: #ffffff;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    infoContainer: styled.div `
    ${flex.v.allCenter}
    width: 100%;
    height: calc(100% - 145px);
    gap: 16px;
  `,
    topContainer: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: 115px;
    border-radius: 5px 5px 0px 0px;
    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          color: #cccccc;
          background-color: #444444;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          color: #666666;
          background-color: #cccccc;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    bottomContainer: styled.div `
    ${flex.h.allCenter}
    width: 100%;
    height: 30px;
    color: #999999;
    font-size: 12px;
    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          color: #cccccc;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          color: #777777;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    logOutButton: styled.div `
    ${flex.h.allCenter};
    width: 96px;
    height: 30px;
    color: #ffffff;
    background-color: #dc3572;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #cc3572;
    }
  `,
    userName: styled.div `
    font-size: 18px;
    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          color: #eeeeee;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          color: #333333;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    itemText: styled.div `
    ${flex.h.allCenter};
    width: 80px;
    height: 24px;

    border-radius: 8px;
    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          background-color: #555555;
          color: #eeeeee;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          background-color: #eeeeee;
          color: #111111;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    itemValue: styled.div `
    ${flex.h.allCenter};

    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          color: #dddddd;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          color: #666666;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
};
