import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import IntradayTrendChart from '~/modules/trendChart';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { Styleds } from './styleds';
import { winner98_agentStore } from './winner98_agentStore';
import { Winner98_DefaultListSymbol } from './component/winner98_DefaultListSymbol';
import { winner98_strategiesGroup } from './winner98_initStrategies';
import { Winner98_MasterSymbol } from './component/winner98_MasterSymbol';
import { Winner98_PopularSymbol } from './component/winner98_PopularSymbol';
import { LinkGroup } from './winner98_Topbar';
import useMedia from '~/hooks/useMedia';
import { PageModeButtonSelector } from './component/winner98_PageModeSelector';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { Winner98_MultipleWatchListSymbol } from './component/winner98_MultipleWatchListSymbol';
import { WatchSymbolToggleSelector } from '~/pages/heineken_template/_col/col_WatchSymbolToggleGroupSelector';
import { VolumeFilter } from './component/VolumeFilter';
import { Winner98_ChartPositionSelector } from './component/winner98_ChartPositionSelecter';
const aaaConfig = [
    { groupName: 'winner98_stock_group_1', displayText: '群組1' },
    { groupName: 'winner98_stock_group_2', displayText: '群組2' },
    { groupName: 'winner98_stock_group_3', displayText: '群組3' },
];
export const Winner98_SidebarPane1 = memo(function Winner98_SidebarPane1() {
    const charting = useSnapshot(store.charting);
    const { isPc } = useMedia();
    const state = useSnapshot(winner98_agentStore);
    const displayTrendChart = state.displayTrendChart;
    return (<Styleds.Sidebar>
      {!isPc && <LinkGroup />}
      <Winner98_ChartPositionSelector />
      <Styleds.TitleQuoteContent>
        <TitleSymbolQuote.DefaulVolume symbol={charting.symbol}/>
        <DisplayButton />
      </Styleds.TitleQuoteContent>
      {state.displayTrendChart === true && (<Styleds.TrendChartContent>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-15} priceTicksSize={11}/>
        </Styleds.TrendChartContent>)}
      <Styleds.WatchSymbolContent>
        <WatchSymbolToggleSelector config={aaaConfig}/>
      </Styleds.WatchSymbolContent>
      <PageModeButtonSelector />
      <Styleds.PanlContent displayTrendChart={displayTrendChart}>
        <winner98_agentStore.tabs1.WithContent for='主力選股'>
          <div css={listContentCss}>
            <Winner98_DefaultListSymbol />
          </div>
          <div css={vilterContentCss}>
            <VolumeFilter />
          </div>
        </winner98_agentStore.tabs1.WithContent>
        <winner98_agentStore.tabs1.WithContent for='熱門股'>
          <Winner98_PopularSymbol />
        </winner98_agentStore.tabs1.WithContent>
        <winner98_agentStore.tabs1.WithContent for='大師選股'>
          <Winner98_MasterSymbol />
        </winner98_agentStore.tabs1.WithContent>
        <winner98_agentStore.tabs1.WithContent for='自選股'>
          <Winner98_MultipleWatchListSymbol />
        </winner98_agentStore.tabs1.WithContent>
      </Styleds.PanlContent>
    </Styleds.Sidebar>);
});
const listContentCss = css `
  ${fill_vertical_cross_center}
  height: calc(100% - 40px);
  width: 100%;
`;
const vilterContentCss = css `
  heigh: 40px;
  width: 100%;
`;
export const Winner98StrategyButton1 = memo(function Winner98StrategyButton1() {
    const charting = useSnapshot(store.charting);
    return (<div css={css `
        ${fill_horizontal_all_center};
        width: 100%;
        height: 32px;
        gap: 4px;
      `}>
      {winner98_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<Styleds.StrategyButton key={config.displayName}>
            <FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                    winner98_agentStore.strategy = config.displayName;
                }}>
              {config.displayName}
            </FuiButton.Display>
          </Styleds.StrategyButton>);
        })}
    </div>);
});
export const DisplayButton = memo(function DisplayButton() {
    const state = useSnapshot(winner98_agentStore);
    const displayTrendChart = state.displayTrendChart;
    return (<div css={css `
        ${fill_horizontal_all_center}
        width: 36px;
        height: 20px;
        background-color: #363a49;
        border-radius: 2px;
        font-size: 8px;
        cursor: pointer;
        &:hover {
          background-color: #585d72;
        }
      `} onClick={() => displayTrendChart === true
            ? (winner98_agentStore.displayTrendChart = false)
            : (winner98_agentStore.displayTrendChart = true)}>
      {displayTrendChart === true ? '▲' : '▼'}
    </div>);
});
